import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { TeamMember } from "../TeamMember";
import { useFormContext, useFieldArray } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { CommunicationType, Status } from "../../interfaces/Agent";
import { TeamMemberHeader } from "../TeamMemberHeader";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RemoveMemberDialog } from "../dialog/RemoveMemberDialog";

export interface TeamAccordianSectionProps {
  disabled: boolean;
  expanded: boolean[];
  expandedIndex: number;
  setExpandedIndex: (index: number) => void;
  onChange: (
    index: number,
    expanded: boolean,
    event?: React.SyntheticEvent
  ) => void;
}

export function TeamAccordianSection(props: TeamAccordianSectionProps) {
  const { disabled, expanded, expandedIndex, setExpandedIndex, onChange } =
    props;

  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "agents",
  });

  const handleRemoveAgent = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };
  const handleAddAgent = () => {
    if (fields.length < 10) {
      append({
        status: Status.AVAILABLE,
        id: uuidv4(),
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        availability: {},
        prefered_communication: CommunicationType.TEXT,
      });
      setExpandedIndex(fields.length);
    }
  };

  useEffect(() => {
    if (errors.agents)
      setExpandedIndex(parseInt(Object.keys(errors.agents)[0]));
  }, [errors]);

  const [dialogIndex, setDialogIndex] = useState(-1);
  return (
    <>
      <RemoveMemberDialog
        open={dialogIndex >= 0}
        onClose={() => setDialogIndex(-1)}
        onCancel={() => setDialogIndex(-1)}
        onOk={() => handleRemoveAgent(dialogIndex)}
      />

      <Stack
        sx={{ width: "100%" }}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Stack
          sx={{ width: "100%" }}
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          {fields.map((agent, index: number) => {
            const divid = `agent-wrapper-${index}`;
            const shouldOpen = index === expandedIndex;
            return (
              <Accordion
                id={divid}
                key={agent.id}
                slotProps={{ transition: { unmountOnExit: true } }}
                disableGutters
                elevation={0}
                square
                sx={{
                  width: "100%",
                  backgroundColor: "#F8FAFE",
                  "&:hover": !shouldOpen
                    ? {
                        backgroundColor: "#DCE0F0",
                      }
                    : undefined,
                }}
                disabled={disabled}
                expanded={shouldOpen}
                onChange={(e, isExpanded) => {
                  setExpandedIndex(isExpanded ? index : -1);
                  if (isExpanded)
                    setTimeout(() => {
                      document.getElementById(divid)?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }, 500);
                }}
              >
                <AccordionSummary>
                  <TeamMemberHeader index={index} />
                </AccordionSummary>
                <AccordionDetails>
                  <TeamMember
                    index={index}
                    showRemove={fields.length > 1}
                    expanded={expanded.length > index ? expanded[index] : false}
                    onAgentRemove={() => setDialogIndex(index)}
                    onExpandChange={(e, expand) => {
                      onChange(index, e, expand);
                      if (expand)
                        setTimeout(() => {
                          document.getElementById(divid)?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }, 500);
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
        <Button
          variant="outlined"
          type="button"
          sx={{ width: { xs: "100%", md: 200 } }}
          disabled={disabled}
          onClick={() => {
            handleAddAgent();
          }}
        >
          Add more staff
        </Button>
      </Stack>
    </>
  );
}
